





















import {Component, Vue, Prop} from 'vue-property-decorator'

import BaseIcon from '@/components/base/BaseIcon.vue'
import {NewsModel} from "@/core/models/NewsModel";
import NewsService from "@/core/services/NewsService";
import {namespace} from "vuex-class";

const NewsStore = namespace("NewsStore");

@Component({
  name: 'NewsItem',
  components: {
    BaseIcon,
  },
})
export default class NewsItem extends Vue {
  @Prop()
  news!: NewsModel

  @NewsStore.Action
  private getNews!: () => Promise<void>;

  async newsClick() {
    await NewsService.readNews(this.news.id)
    this.news.read_at = "read"
    await this.getNews()
  }

}
